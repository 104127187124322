// import * as Sentry from '@sentry/remix'
import { startTransition, StrictMode } from 'react'
import { hydrateRoot } from 'react-dom/client'

import { RemixBrowser } from '@remix-run/react'

// Sentry.init({
// 	dsn: 'https://499dea867f547a2e0ccd5efc6996e018@o4507870792253440.ingest.us.sentry.io/4507870798282752',
// 	tracesSampleRate: 1,
// 	replaysSessionSampleRate: 0.1,
// 	replaysOnErrorSampleRate: 1,

// 	integrations: [
// 		Sentry.browserTracingIntegration({
// 			useEffect,
// 			useLocation,
// 			useMatches,
// 		}),
// 		// Sentry.replayIntegration(),
// 	],
// })

startTransition(() => {
	hydrateRoot(
		document,
		<StrictMode>
			<RemixBrowser />
		</StrictMode>,
	)
})
